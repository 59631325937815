import * as React from "react";
import { useEffect } from "react";
import { Cookie } from "../../utils/Cookie";
import { StringParam, useQueryParam } from "use-query-params";

const GlobalQueryParams: React.FunctionComponent = () => {
  const [msclkid] = useQueryParam("msclkid", StringParam);
  const [fbclid] = useQueryParam("fbclid", StringParam);
  const [li_fat_id] = useQueryParam("li_fat_id", StringParam);
  const [consultcall] = useQueryParam("consultcall", StringParam);
  const [utmCampaign] = useQueryParam("utm_campaign", StringParam);
  const [utmMedium] = useQueryParam("utm_medium", StringParam);
  const [utmSource] = useQueryParam("utm_source", StringParam);

  useEffect(() => {
    if (msclkid) Cookie.setCookie("msclkid", msclkid, 30);
    if (fbclid) Cookie.setCookie("fbclid", msclkid, 30);
    if (li_fat_id) Cookie.setCookie("li_fat_id", msclkid, 30);
    if (consultcall) Cookie.setCookie("consultcall", consultcall, 30);
    if (utmCampaign?.toLowerCase()?.includes("pmax"))
      sessionStorage.setItem("pmax", "true");

    if (utmCampaign) sessionStorage.setItem("utm_campaign", utmCampaign);
    if (utmMedium) sessionStorage.setItem("utm_medium", utmMedium);
    if (utmSource) sessionStorage.setItem("utm_source", utmSource);
  }, [
    msclkid,
    fbclid,
    li_fat_id,
    consultcall,
    utmCampaign,
    utmMedium,
    utmSource,
  ]);
  return <></>;
};

export default GlobalQueryParams;
